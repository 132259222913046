<template>
  <div class="contactus">
    <div class="first_view">
      <div class="title">
        <h3>{{ $t('contactus.first_view.title') }}</h3>
      </div>
      <div class="subtitle">
        <p>{{ $t('contactus.first_view.subtitle') }}</p>
      </div>
    </div>
    <div class="second_view">
      <ul>
        <li class="card">
          <div class="card_title">
            <p>{{ $t('by_phone') }}</p>
          </div>
          <div class="card_content">
            <div class="card_schedule">
              <div class="days">
                <p>{{ $t('contactus.second_view.cards.phone.schedule_days') }}</p>
              </div>
              <div class="hours">
                <p>{{ $t('contactus.second_view.cards.phone.schedule_hours') }}</p>
              </div>
            </div>
            <div class="card_link">
              <a href="tel:1-581-221-0442">+1 (581)-221-0442</a>
            </div>
          </div>
        </li>
        <li class="card">
          <div class="card_title">
            <p>{{ $t('by_email') }}</p>
          </div>
          <div class="card_content">
            <div class="card_schedule">
              <div class="days">
                <p>{{ $t('contactus.second_view.cards.email.schedule_days') }}</p>
              </div>
              <div class="hours">
                <p>{{ $t('contactus.second_view.cards.email.schedule_hours') }}</p>
              </div>
            </div>
            <div class="card_link">
              <a href="mailto:contact@beautec.ca">contact@beautec.ca</a>
            </div>
          </div>
        </li>
        <li class="card">
          <div class="card_title">
            <p>{{ $t('by_social_network') }}</p>
          </div>
          <div class="card_content">
            <div class="card_schedule">
              <div class="days">
                <p>{{ $t('contactus.second_view.cards.social_network.schedule_days') }}</p>
              </div>
              <div class="hours">
                <p>{{ $t('contactus.second_view.cards.social_network.schedule_hours') }}</p>
              </div>
            </div>
            <div class="card_link">
              <div class="icons col-f">
                <div class="facebook">
                  <a target="blank" href="//fb.me/squaretymedia">
                    <i class="fab fa-facebook-f"></i>
                  </a>
                </div>
                <div class="twitter">
                  <a target="blank" href="//twitter.com/squaretymedia">
                    <i class="fab fa-twitter"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="third_view contact_form_container">
      <ContactForm></ContactForm>
    </div>
  </div>
</template>
<style scoped src="@/assets/css/contactus.less" lang="less"></style>
<script>
import ContactForm from '../components/ContactForm.vue'

export default {
  name: 'contact-us',
  components: {
    ContactForm
  },
  metaInfo() {
    const locale = this.locale
    return {
      title: this.$i18n.t('contactus.title'),
      titleTemplate: null,
      meta: [
        {
          name: 'description',
          content:
            locale === 'fr'
              ? `L'Agence Web Beautec utilise des technologies modernes et rapides. Nous créons des sites internet et applications web qui font fière allure. Nous nous concentrons sur les entreprises qui veulent faire une différence pour l'environnement et internet.`
              : `Beautec uses modern and fast technologies. We create websites and web applications that look great. We focus on companies that want to make a difference for the environment and the Internet.`
        },
        { name: 'image', content: 'https://cdn.beautec.ca/images/website/first_view_contact.jpg' },
        // OpenGraph data (Most widely used)
        {
          property: 'og:title',
          content:
            locale === 'fr'
              ? `Nous Contacter | Beautec - Agence Web technologique moderne - Rimouski`
              : `Contact Us | Beautec - Modern Technological Web Agency - Rimouski`
        },
        { property: 'og:site_name', content: 'Beautec' },
        { property: 'og:type', content: 'website' },
        {
          property: 'og:url',
          content:
            locale === 'fr' ? 'https://beautec.ca/fr/contact-us' : 'https://beautec.ca/en/contact-us'
        },
        { property: 'og:image', content: 'https://cdn.beautec.ca/images/website/first_view_contact.jpg' },
        {
          property: 'og:description',
          content:
            locale === 'fr'
              ? `L'Agence Web Squarety Média utilise des technologies modernes et rapides. Nous créons des sites internet et applications web qui font fière allure. Nous nous concentrons sur les entreprises qui veulent faire une différence pour l'environnement et internet.`
              : `Squarety Media uses modern and fast technologies. We create websites and web applications that look great. We focus on companies that want to make a difference for the environment and the Internet.`
        },

        // Twitter card
        {
          property: 'twitter:title',
          content:
            locale === 'fr'
              ? `Nous Contacter | Squarety Média - Agence Web technologique moderne - Rimouski`
              : `Contact Us | Squarety Media - Modern Technological Web Agency - Rimouski`
        },
        { property: 'twitter:card', content: 'summary' },
        {
          property: 'twitter:site',
          content:
            locale === 'fr' ? 'https://squaretymedia.com/fr/contact-us' : 'https://squaretymedia.com/en/contact-us'
        },
        { property: 'twitter:creator', content: '@squaretymedia' },
        {
          property: 'twitter:image:src',
          content: 'https://cdn.squaretymedia.com/images/website/first_view_contact.jpg'
        },
        {
          property: 'twitter:description',
          content:
            locale === 'fr'
              ? `L'Agence Web Squarety Média utilise des technologies modernes et rapides. Nous créons des sites internet et applications web qui font fière allure. Nous nous concentrons sur les entreprises qui veulent faire une différence pour l'environnement et internet.`
              : `Squarety Media uses modern and fast technologies. We create websites and web applications that look great. We focus on companies that want to make a difference for the environment and the Internet.`
        },

        // Google / Schema.org markup:
        {
          itemprop: 'name',
          content:
            locale === 'fr'
              ? `Nous Contacter | Squarety Média - Agence Web technologique moderne - Rimouski`
              : `Contact Us | Squarety Media - Modern Technological Web Agency - Rimouski`
        },
        {
          itemprop: 'description',
          content:
            locale === 'fr'
              ? `L'Agence Web Squarety Média utilise des technologies modernes et rapides. Nous créons des sites internet et applications web qui font fière allure. Nous nous concentrons sur les entreprises qui veulent faire une différence pour l'environnement et internet.`
              : `Squarety Media uses modern and fast technologies. We create websites and web applications that look great. We focus on companies that want to make a difference for the environment and the Internet.`
        },
        { itemprop: 'image', content: 'https://cdn.squaretymedia.com/images/website/first_view_contact.jpg' }
      ],
      link: [
        locale === 'fr'
          ? { rel: 'canonical', href: 'https://squaretymedia.com/fr/contact-us' }
          : { rel: 'canonical', href: 'https://squaretymedia.com/en/contact-us' }
      ]
    }
  }
}
</script>
