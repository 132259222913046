import api from '@/services/api'

const state = {
  user: null,
  status: null,
  token: null,
  refreshToken: null,
  emailCooldown: null
}

const getters = {
  isAuthenticated: state => !!state.token,
  authStatus: state => state.status
}

const actions = {
  register: ({ commit }, user) => {
    return new Promise((resolve, reject) => {
      api()
        .post('/users/register', user)
        .then(response => {
          commit('loginSuccess', {
            token: response.data.token,
            refreshToken: response.data.refreshToken,
            user: response.data.user
          })
          resolve(response)
        })
        .catch(err => {
          reject(err.response.data.error)
        })
    })
  },
  login: ({ commit, dispatch }, user) => {
    return new Promise((resolve, reject) => {
      commit('loginRequest')
      api()
        .post('/users/login', user)
        .then(response => {
          commit('loginSuccess', {
            token: response.data.token,
            refreshToken: response.data.refreshToken,
            user: response.data.user
          })

          resolve(response)
        })
        .catch(err => {
          commit('loginError', err)
          reject(err.response.data.error)
        })
    })
  },
  resendVerificationMail: ({ commit }) => {
    return new Promise((resolve, reject) => {
      api()
        .get(`/users/send-verification-mail${process.env.NODE_ENV === 'development' ? '?dev=true' : ''}`)
        .then(response => {
          commit('emailSent')
          resolve(response)
        })
        .catch(err => {
          reject(err.response.data.error)
        })
      resolve()
    })
  },
  refreshAccessToken: ({ commit, state }) => {
    return new Promise((resolve, reject) => {
      api()
        .post('/users/refreshAccessToken', { email: state.user.email, refreshToken: state.refreshToken })
        .then(response => {
          commit('loginSuccess', {
            token: response.data.token,
            refreshToken: response.data.refreshToken,
            user: response.data.user
          })

          resolve(response)
        })
        .catch(err => {
          commit('loginError', err)
          reject(err.response.data.error)
        })
    })
  },
  sendPasswordResetToken: ({ commit, state }, email) => {
    return new Promise((resolve, reject) => {
      api()
        .post(`/users/send-password-reset-mail`, { email })
        .then(response => {
          commit('emailSent')
          resolve(response)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  resetPassword: ({ commit, state }, payload) => {
    return new Promise((resolve, reject) => {
      api()
        .post(`/user/reset-password`, payload)
        .then(response => {
          resolve(response)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  logout: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit('logout')
      resolve()
    })
  },
  update: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      api()
        .put('/users', payload)
        .then(response => {
          commit('update', response.data.user)
          if (Object.keys(payload).includes('email')) {
            commit('emailSent')
          }
          resolve(response)
        })
        .catch(err => {
          reject(err.response.data.error)
        })
    })
  },
  verify: ({ commit }, token) => {
    return new Promise((resolve, reject) => {
      api()
        .post('/users/verification', token)
        .then(response => {
          commit('update', response.data.user)
          resolve(response)
        })
        .catch(err => {
          console.trace(err.response)
          reject(err.response.data.error)
        })
    })
  },
  emailCooldownFinished: ({ commit }) => {
    commit('emailCooldownFinished')
  }
}

const mutations = {
  loginRequest: state => {
    state.status = 'loading'
  },
  loginSuccess: (state, payload) => {
    state.status = 'success'
    state.token = payload.token
    state.refreshToken = payload.refreshToken
    state.user = payload.user
  },
  loginError: state => {
    state.status = 'error'
  },
  logout: state => {
    state.user = null
    state.token = null
    state.refreshToken = null
    state.status = null
  },
  update: (state, user) => {
    state.user = user
  },
  emailSent: state => {
    state.emailCooldown = new Date()
  },
  emailCooldownFinished: state => {
    state.emailCooldown = null
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
