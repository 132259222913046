const state = {
  isCookieBannerShown: null
}

const getters = {}

const actions = {
  setCookieBannerVisible({ commit }, visibility) {
    commit('setCookieBannerVisible', visibility)
  }
}

const mutations = {
  setCookieBannerVisible(state, visibility) {
    state.isCookieBannerShown = visibility
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
