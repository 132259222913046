import Vue from 'vue'
import Router from 'vue-router'
import { TranslationService } from './TranslationService'
import LangTemplate from './views/LangTemplate.vue'
// import Elements from './views/Elements.vue'
// import Login from './views/login/Login.vue'
// import ForgotPassword from './views/login/ForgotPassword.vue'
// import Confirm from './views/login/Confirm.vue'

import Home from './views/Home.vue'
import Terms from './views/legal/Terms.vue'
import WebApplication from './views/products/WebApplication.vue'
import Website from './views/products/Website.vue'
// import WebHosting from './views/products/WebHosting.vue'
import Portfolio from './views/Portfolio.vue'
import SRPQ from './views/projects/Srpq.vue'
import Optezbeaute from './views/projects/Optezbeaute.vue'
import Odeyr from './views/projects/Odeyr.vue'
import TermsOfSale from './views/TermsOfSale.vue'
import ContactUs from './views/ContactUs.vue'

Vue.use(Router)

let router = new Router({
  mode: 'history',
  base: '/',
  routes: [
    {
      path: '/:lang',
      component: LangTemplate,
      beforeEnter: TranslationService.routeMiddleware,
      children: [
        {
          path: '',
          name: 'home',
          alias: 'home',
          component: Home
        },
        {
          name: 'terms',
          path: 'legal/terms',
          component: Terms
        },
        {
          name: 'web-application',
          path: 'products/web-application',
          component: WebApplication
        },
        {
          name: 'website',
          path: 'products/website',
          component: Website
        },
        /*
        {
          name: 'web-hosting',
          path: '/:lang/products/web-hosting',
          component: WebHosting
        },
        */
        {
          name: 'portfolio',
          path: 'portfolio',
          component: Portfolio
        },
        {
          name: 'srpq',
          path: 'porfolio/customers/srpq',
          component: SRPQ
        },
        {
          name: 'optez_beaute',
          path: 'portfolio/customers/optez_beaute',
          component: Optezbeaute
        },
        {
          name: 'odeyr',
          path: 'portfolio/customers/odeyr',
          component: Odeyr
        },
        {
          name: 'terms-of-sale',
          alias: 'cgv',
          path: 'terms-of-sale',
          component: TermsOfSale
        },
        {
          name: 'contact-us',
          path: 'contact-us',
          component: ContactUs
        } /*,
         {
          path: '/login',
          name: 'login',
          alias: '/login',
          meta: {
            layout: 'footer-only',
            guest: true
          },
          component: Login
        },
        {
          path: '/forgot-password',
          name: 'forgot-password',
          meta: {
            layout: 'footer-only',
            guest: true
          },
          component: ForgotPassword
        },
        {
          path: '/confirm',
          name: 'confirm',
          meta: {
            layout: 'footer-only',
            requiresAuth: true
          },
          component: Confirm
        },
        {
          path: '/elements',
          name: 'elements',
          component: Elements
        } */
      ]
    },
    {
      // Redirect user to supported lang version.
      path: '*',
      redirect(to) {
        return TranslationService.getUserSupportedLang()
      }
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
