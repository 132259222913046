import i18n from './i18n'
import axios from 'axios'

const TranslationService = {
  get defaultLanguage() {
    return process.env.VUE_APP_I18N_FALLBACK_LOCALE
  },
  get supportedLanguages() {
    return ['fr', 'en']
  },
  get currentLanguage() {
    return i18n.locale
  },
  set currentLanguage(lang) {
    i18n.locale = lang
  },
  /**
   * Gets the first supported language that matches the user's
   * @return {String}
   */
  getUserSupportedLang() {
    const userPreferredLang = TranslationService.getUserLang()

    // Check if user preferred browser lang is supported
    if (TranslationService.isLangSupported(userPreferredLang.lang)) {
      return userPreferredLang.lang
    }
    // Check if user preferred lang without the ISO is supported
    if (TranslationService.isLangSupported(userPreferredLang.langNoISO)) {
      return userPreferredLang.langNoISO
    }
    return TranslationService.defaultLanguage
  },
  /**
   * Returns the users preferred language
   */
  getUserLang() {
    const lang = window.navigator.language || window.navigator.userLanguage || TranslationService.defaultLanguage
    return {
      lang: lang,
      langNoISO: lang.split('-')[0]
    }
  },
  /**
   * Sets the language to various services (axios, the html tag etc)
   * @param {String} lang
   * @return {String} lang
   */
  setI18nLanguageInServices(lang) {
    TranslationService.currentLanguage = lang
    axios.defaults.headers.common['Accept-Language'] = lang
    axios.defaults.baseURL = process.env.VUE_APP_API_URL + '/' + lang + '/' + process.env.VUE_APP_API_VERSION
    document.querySelector('html').setAttribute('lang', lang)
    return lang
  },
  /**
   * Loads new translation messages and changes the language when finished
   * @param lang
   * @return {Promise<any>}
   */
  changeLanguage(lang) {
    if (!TranslationService.isLangSupported(lang)) return Promise.reject(new Error('Language not supported'))
    if (i18n.locale === lang) return Promise.resolve(lang) // has been loaded prior
    i18n.locale = lang
    return TranslationService.setI18nLanguageInServices(lang)
  },
  /**
   * Checks if a lang is supported
   * @param {String} lang
   * @return {boolean}
   */
  isLangSupported(lang) {
    return TranslationService.supportedLanguages.includes(lang)
  },
  /**
   * Checks if the route's param is supported, if not, redirects to the first supported one.
   * @param {Route} to
   * @param {Route} from
   * @param {Function} next
   * @return {*}
   */
  routeMiddleware(to, from, next) {
    // Load async message files here
    const lang = to.params.lang
    if (!TranslationService.isLangSupported(lang)) return next(TranslationService.getUserSupportedLang())
    TranslationService.changeLanguage(lang)
    next()
  }
}

export { TranslationService }
