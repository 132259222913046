<template>
  <div class="legal col-pc-12">
    <div class="first_view">
      <div class="flexbox">
        <div class="tabs">
          <ul>
            <li>
              <a :class="{ 'is-active': tabsel == '#termsofservice' }" @click="updateHash('#termsofservice')">{{
                $t('terms_of_service')
              }}</a>
            </li>
            <li>
              <a :class="{ 'is-active': tabsel == '#privacypolicy' }" @click="updateHash('#privacypolicy')">{{
                $t('privacy_policy')
              }}</a>
            </li>
          </ul>
        </div>
        <div class="content">
          <div v-show="tabsel == '#termsofservice'">
            <TermsOfService></TermsOfService>
          </div>
          <div v-show="tabsel == '#privacypolicy'">
            <PrivacyPolicy></PrivacyPolicy>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped src="@/assets/css/legal.less" lang="less"></style>
<style>
a {
  transition: all 0.3s;
}
a:hover {
  color: #f2684a;
  transition: all 0.3s;
}
</style>
<script>
import TermsOfService from '@/components/TermsOfService.vue'
import PrivacyPolicy from '@/components/PrivacyPolicy.vue'

export default {
  name: 'terms',
  components: {
    TermsOfService,
    PrivacyPolicy
  },
  data() {
    return {
      tabsel: this.$route.hash
    }
  },
  methods: {
    updateHash(newHash) {
      this.$router.push({ hash: newHash })
    }
  },
  watch: {
    // whenever question changes, this function will run
    '$route.hash': function(newHash) {
      this.tabsel = newHash
    }
  },
  metaInfo() {
    const locale = this.locale
    return {
      title: this.$i18n.t('legal_pagetitle'),
      titleTemplate: null,
      meta: [
        {
          name: 'description',
          content:
            locale === 'fr'
              ? `L'Agence Web Squarety Média utilise des technologies modernes et rapides. Nous créons des sites internet et applications web qui font fière allure. Nous nous concentrons sur les entreprises qui veulent faire une différence pour l'environnement et internet.`
              : `Squarety Media uses modern and fast technologies. We create websites and web applications that look great. We focus on companies that want to make a difference for the environment and the Internet.`
        },
        { name: 'image', content: 'https://cdn.squaretymedia.com/images/website/first_view_website.jpg' },
        // OpenGraph data (Most widely used)
        {
          property: 'og:title',
          content:
            locale === 'fr'
              ? `Conditions et Politiques | Squarety Média - Agence Web technologique moderne - Rimouski`
              : `Terms & Conditions | Squarety Media - Modern Technological Web Agency - Rimouski`
        },
        { property: 'og:site_name', content: 'Squarety Media' },
        { property: 'og:type', content: 'website' },
        {
          property: 'og:url',
          content:
            locale === 'fr' ? 'https://squaretymedia.com/fr/legal/terms' : 'https://squaretymedia.com/en/legal/terms'
        },
        { property: 'og:image', content: 'https://cdn.squaretymedia.com/images/website/first_view_website.jpg' },
        {
          property: 'og:description',
          content:
            locale === 'fr'
              ? `L'Agence Web Squarety Média utilise des technologies modernes et rapides. Nous créons des sites internet et applications web qui font fière allure. Nous nous concentrons sur les entreprises qui veulent faire une différence pour l'environnement et internet.`
              : `Squarety Media uses modern and fast technologies. We create websites and web applications that look great. We focus on companies that want to make a difference for the environment and the Internet.`
        },

        // Twitter card
        {
          property: 'twitter:title',
          content:
            locale === 'fr'
              ? `Conditions et Politiques | Squarety Média - Agence Web technologique moderne - Rimouski`
              : `Terms & Conditions | Squarety Media - Modern Technological Web Agency - Rimouski`
        },
        { property: 'twitter:card', content: 'summary' },
        {
          property: 'twitter:site',
          content:
            locale === 'fr' ? 'https://squaretymedia.com/fr/legal/terms' : 'https://squaretymedia.com/en/legal/terms'
        },
        { property: 'twitter:creator', content: '@squaretymedia' },
        {
          property: 'twitter:image:src',
          content: 'https://cdn.squaretymedia.com/images/website/first_view_website.jpg'
        },
        {
          property: 'twitter:description',
          content:
            locale === 'fr'
              ? `L'Agence Web Squarety Média utilise des technologies modernes et rapides. Nous créons des sites internet et applications web qui font fière allure. Nous nous concentrons sur les entreprises qui veulent faire une différence pour l'environnement et internet.`
              : `Squarety Media uses modern and fast technologies. We create websites and web applications that look great. We focus on companies that want to make a difference for the environment and the Internet.`
        },

        // Google / Schema.org markup:
        {
          itemprop: 'name',
          content:
            locale === 'fr'
              ? `Conditions et Politiques | Squarety Média - Agence Web technologique moderne - Rimouski`
              : `Terms & Conditions | Squarety Media - Modern Technological Web Agency - Rimouski`
        },
        {
          itemprop: 'description',
          content:
            locale === 'fr'
              ? `L'Agence Web Squarety Média utilise des technologies modernes et rapides. Nous créons des sites internet et applications web qui font fière allure. Nous nous concentrons sur les entreprises qui veulent faire une différence pour l'environnement et internet.`
              : `Squarety Media uses modern and fast technologies. We create websites and web applications that look great. We focus on companies that want to make a difference for the environment and the Internet.`
        },
        { itemprop: 'image', content: 'https://cdn.squaretymedia.com/images/website/first_view_website.jpg' }
      ],
      link: [
        locale === 'fr'
          ? { rel: 'canonical', href: 'https://squaretymedia.com/fr/legal/terms' }
          : { rel: 'canonical', href: 'https://squaretymedia.com/en/legal/terms' }
      ]
    }
  }
}
</script>
