<template>
  <div class="portfolio">
    <div class="first_view">
      <div class="title">
        <h3 class="primary">{{ $t('portfolio.first_view.title') }}</h3>
      </div>
      <div class="subtitle">
        <p>{{ $t('portfolio.first_view.subtitle') }}</p>
      </div>
    </div>
    <div class="second_view">
      <div class="view_filter">
        <div class="text">
          <p>{{ $t('portfolio.second_view.text') }}</p>
        </div>
        <select v-model="searchType" name="view_filter" id="portfolio_filter" class="uk-select col-all-6">
          <option value="all">{{ $t('all') }}</option>
          <option value="consulting">{{ $t('menu.consulting') }}</option>
          <option value="ecommerce">{{ $t('menu.e_commerce') }}</option>
          <option value="webapplication">{{ $t('menu.web_application') }}</option>
          <option value="website">{{ $t('menu.website') }}</option>
        </select>
      </div>
      <div class="item_container col-lgpc-10 col-mdpc-10 col-mobile-10">
        <ul>
          <li class="item col-lgpc-4 col-mdpc-6" v-for="item in filteredItems" :key="item">
            <div class="title_container">
              <div class="title" :title="item.name">
                {{ $t('portfolio.' + item.name) }}
              </div>
              <div class="type">
                <p>{{ $t('menu.' + item.typeText) }}</p>
              </div>
            </div>
            <div class="image" uk-lightbox>
              <div class="overlay" v-if="item.tag !== ''">
                <p>{{ item.tag }}</p>
              </div>
              <a :href="item.image">
                <div class="overlay-onhover" v-if="item.tag !== $t('in_progress')">
                  <div class="expend-icon">
                    <i class="fas fa-expand-arrows-alt"></i>
                  </div>
                  <p>{{ $t('click_to_expend') }}</p>
                </div>
                <img :data-src="item.image" width="100%" :alt="$t('portfolio.image_alt')" uk-img />
              </a>
            </div>
            <div class="button">
              <router-link
                class="col-all-12"
                :to="{
                  name: item.link,
                  params: { id: item.id }
                }"
                v-if="item.link !== 'coming_soon' && item.link !== 'no_link'"
              >
                <button class="secondary-full col-all-12">
                  <h3>{{ $t('portfolio.learn_more') }}</h3>
                </button>
              </router-link>
              <div class="coming_soon" v-if="item.link === 'coming_soon'">
                <button class="secondary-full col-all-12" disabled>
                  <h3>{{ $t('coming_soon') }}</h3>
                </button>
              </div>
              <div class="live_link" v-if="item.link === 'no_link'">
                <a :href="item.visitLink" target="_blank" class="col-all-12">
                  <button class="secondary-full col-all-12">
                    <h3>{{ $t('portfolio.visit_website') }}</h3>
                  </button>
                </a>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<style scoped src="@/assets/css/portfolio.less" lang="less"></style>
<script>
export default {
  name: 'portfolio',
  data: function() {
    return {
      searchType: 'all',
      items: [
        {
          id: 1,
          name: 'srpq',
          tag: '',
          subtitle: this.$i18n.t('loyal_customer'),
          type: 'website',
          typeText: 'website',
          image: '//cdn.squaretymedia.com/images/portfolio/srpq/srpq-screenshot.png',
          link: 'no_link',
          visitLink: 'https://srpq.beautec.ca'
        },
        {
          id: 2,
          name: 'optez_beaute',
          tag: '',
          subtitle: this.$i18n.t('under_construction'),
          type: 'website',
          typeText: 'website',
          image:
            '//cdn.squaretymedia.com/images/portfolio/optez-beaute/screenshot-optezbeaute.ca-2020.03.26-15_20_47.png',
          link: 'no_link',
          visitLink: 'https://optezbeaute.ca'
        },
        {
          id: 3,
          name: 'leafcad',
          tag: '',
          subtitle: this.$i18n.t('under_construction'),
          type: 'webapplication',
          typeText: 'web_application',
          // image: '//cdn.leafcad.com/images/logos/leafcad-logos/Logo+LeafCAD.png',
          image: '//cdn.squaretymedia.com/images/portfolio/leafcad/screenshot-leafcad.com-2021.05.12-14_03_00.png',
          link: 'no_link',
          visitLink: 'https://leafcad.com'
        },
        {
          id: 4,
          name: 'odeyr',
          tag: '',
          subtitle: this.$i18n.t('under_construction'),
          type: 'website',
          typeText: 'website',
          image: '//cdn.squaretymedia.com/images/portfolio/odeyr/screenshot-odeyr.org-2021.05.12-13_20_12.png',
          link: 'no_link',
          visitLink: 'https://odeyr.org'
        },
        {
          id: 5,
          name: 'optez_beaute_shop',
          tag: '',
          subtitle: this.$i18n.t('under_construction'),
          type: 'ecommerce',
          typeText: 'e_commerce',
          image:
            '//cdn.squaretymedia.com/images/portfolio/optez-beaute/screenshot-boutique.optezbeaute.ca-2021.05.12-13_10_32.png',
          link: 'no_link',
          visitLink: 'https://boutique.optezbeaute.ca'
        },
        {
          id: 6,
          name: 'dojqc_concessionnaire',
          tag: '',
          subtitle: this.$i18n.t('under_construction'),
          type: 'website',
          typeText: 'website',
          image:
            '//cdn.beautec.ca/images/portfolio/dojqc/screencapture-concess-dojqc-beautec-ca-2023-03-30-19_02_04.png',
          link: 'no_link',
          visitLink: 'https://concess-dojqc.beautec.ca'
        },
        {
          id: 7,
          name: 'tomy-bosse-barrette',
          tag: '',
          subtitle: this.$i18n.t('under_construction'),
          type: 'consulting',
          typeText: 'consulting',
          image:
            'https://cdn.beautec.ca/images/portfolio/tomy-bosse-barrette/service-tomy-bosse-barrette.jpg',
          link: 'coming_soon'
        }
      ]
    }
  },
  computed: {
    filteredItems() {
      if (this.searchType === 'all') {
        return this.items
      }
      return this.items.filter(item => {
        return item.type.toLowerCase().indexOf(this.searchType.toLowerCase()) > -1
      })
    }
  },
  metaInfo() {
    const locale = this.locale
    return {
      title: this.$i18n.t('portfolio.title'),
      titleTemplate: null,
      meta: [
        {
          name: 'description',
          content:
            locale === 'fr'
              ? `L'Agence Web Squarety Média utilise des technologies modernes et rapides. Nous créons des sites internet et applications web qui font fière allure. Nous nous concentrons sur les entreprises qui veulent faire une différence pour l'environnement et internet.`
              : `Squarety Media uses modern and fast technologies. We create websites and web applications that look great. We focus on companies that want to make a difference for the environment and the Internet.`
        },
        {
          name: 'image',
          content: 'https://cdn.squaretymedia.com/images/website/first_view_portfolio.jpg'
        },
        // OpenGraph data (Most widely used)
        {
          property: 'og:title',
          content:
            locale === 'fr'
              ? `Portfolio | Squarety Média - Agence Web technologique moderne - Rimouski`
              : `Portfolio | Squarety Media - Modern Technological Web Agency - Rimouski`
        },
        { property: 'og:site_name', content: 'Squarety Media' },
        { property: 'og:type', content: 'website' },
        {
          property: 'og:url',
          content: locale === 'fr' ? 'https://squaretymedia.com/fr/portfolio' : 'https://squaretymedia.com/en/portfolio'
        },
        {
          property: 'og:image',
          content: 'https://cdn.squaretymedia.com/images/website/first_view_portfolio.jpg'
        },
        {
          property: 'og:description',
          content:
            locale === 'fr'
              ? `L'Agence Web Squarety Média utilise des technologies modernes et rapides. Nous créons des sites internet et applications web qui font fière allure. Nous nous concentrons sur les entreprises qui veulent faire une différence pour l'environnement et internet.`
              : `Squarety Media uses modern and fast technologies. We create websites and web applications that look great. We focus on companies that want to make a difference for the environment and the Internet.`
        },

        // Twitter card
        {
          property: 'twitter:title',
          content:
            locale === 'fr'
              ? `Portfolio | Squarety Média - Agence Web technologique moderne - Rimouski`
              : `Portfolio | Squarety Media - Modern Technological Web Agency - Rimouski`
        },
        { property: 'twitter:card', content: 'summary' },
        {
          property: 'twitter:site',
          content: locale === 'fr' ? 'https://squaretymedia.com/fr/portfolio' : 'https://squaretymedia.com/en/portfolio'
        },
        { property: 'twitter:creator', content: '@squaretymedia' },
        {
          property: 'twitter:image:src',
          content: 'https://cdn.squaretymedia.com/images/website/first_view_portfolio.jpg'
        },
        {
          property: 'twitter:description',
          content:
            locale === 'fr'
              ? `L'Agence Web Squarety Média utilise des technologies modernes et rapides. Nous créons des sites internet et applications web qui font fière allure. Nous nous concentrons sur les entreprises qui veulent faire une différence pour l'environnement et internet.`
              : `Squarety Media uses modern and fast technologies. We create websites and web applications that look great. We focus on companies that want to make a difference for the environment and the Internet.`
        },

        // Google / Schema.org markup:
        {
          itemprop: 'name',
          content:
            locale === 'fr'
              ? `Portfolio | Squarety Média - Agence Web technologique moderne - Rimouski`
              : `Portfolio | Squarety Media - Modern Technological Web Agency - Rimouski`
        },
        {
          itemprop: 'description',
          content:
            locale === 'fr'
              ? `L'Agence Web Squarety Média utilise des technologies modernes et rapides. Nous créons des sites internet et applications web qui font fière allure. Nous nous concentrons sur les entreprises qui veulent faire une différence pour l'environnement et internet.`
              : `Squarety Media uses modern and fast technologies. We create websites and web applications that look great. We focus on companies that want to make a difference for the environment and the Internet.`
        },
        {
          itemprop: 'image',
          content: 'https://cdn.squaretymedia.com/images/website/first_view_portfolio.jpg'
        }
      ],
      link: [
        locale === 'fr'
          ? { rel: 'canonical', href: 'https://squaretymedia.com/fr/portfolio' }
          : { rel: 'canonical', href: 'https://squaretymedia.com/en/portfolio' }
      ]
    }
  }
}
</script>
