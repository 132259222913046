<template>
  <div class="footer">
    <div class="pc col-pc-12 col-margin-auto col-mobile-displaynone">
      <div class="section">
        <div class="left contact_section col-pc-4">
          <div class="title">
            <h3>
              {{ $t('interested_in_a_project') }}
            </h3>
          </div>
          <div class="subtitle">
            <p>
              {{ $t('we_can_discuss_it') }}
            </p>
          </div>
          <div class="text">
            <a class="secondary" href="tel:+1-581-221-0442">+1 (581) 221-0442</a>
          </div>
          <div class="text">
            <a class="secondary" href="mailto:contact@beautec.ca">contact@beautec.ca</a>
          </div>
        </div>
        <div class="middle logo_section col-pc-4">
          <div class="logo">
            <img src="//cdn.beautec.ca/images/logos/logo-dark.png" alt="logo" />
          </div>
          <div class="return-top">
            <a @click="scrollToTop()">{{ $t('return_to_top') }} <i class="fas fa-angle-up"></i></a>
          </div>
        </div>
        <div class="right icons_section col-pc-4">
          <div class="icons">
            <a class="secondary" target="_blank" href="//fb.me/squaretymedia">
              <i class="fab fa-facebook-f"></i>
            </a>
            <a class="secondary" target="_blank" href="//twitter.com/squaretymedia">
              <i class="fab fa-twitter"></i>
            </a>
          </div>
          <div class="subtitle">
            <p>{{ $t('stay_tuned') }}</p>
          </div>
        </div>
      </div>
      <div class="divider"></div>
      <div class="section copyrights_section">
        <div class="subtitle copyrights col-pc-6">
          <p>© 2015 - {{ new Date().getFullYear() }} {{ $t('copyrights') }}</p>
        </div>
        <div class="legal col-pc-6">
          <div class="text">
            <router-link class="secondary" :to="{ name: 'terms', hash: '#termsofservice' }">{{
              $t('terms_of_service')
            }}</router-link>
          </div>
          <div class="text">
            <router-link class="secondary" :to="{ name: 'terms-of-sale' }">{{ $t('terms_of_sale') }}</router-link>
          </div>
          <div class="text">
            <router-link class="secondary" :to="{ name: 'terms', hash: '#privacypolicy' }">{{
              $t('privacy_policy')
            }}</router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="mobile col-mobile-12 col-pc-displaynone">
      <div class="section logo_section">
        <div class="logo">
          <img src="//cdn.beautec.ca/images/logos/logo-dark.png" alt="logo" />
        </div>
      </div>
      <div class="section contact_section">
        <div class="title">
          <h3>
            {{ $t('interested_in_a_project') }}
          </h3>
        </div>
        <div class="subtitle">
          <p>
            {{ $t('we_can_discuss_it') }}
          </p>
        </div>
        <div class="text">
          <a class="secondary" href="tel:+1-581-221-0442">+1 (581) 221-0442</a>
        </div>
        <div class="text">
          <a class="secondary" href="mailto:contact@beautec.ca">contact@beautec.ca</a>
        </div>
      </div>
      <div class="section social_section">
        <div class="icons">
          <a class="secondary" target="_blank" href="//fb.me/squaretymedia">
            <i class="fab fa-facebook-f"></i>
          </a>
          <a class="secondary" target="_blank" href="//twitter.com/squaretymedia">
            <i class="fab fa-twitter"></i>
          </a>
        </div>
        <div class="subtitle">
          <p>{{ $t('stay_tuned') }}</p>
        </div>
      </div>
      <div class="section legal_section">
        <div class="title">
          <h3>{{ $t('legal') }}</h3>
        </div>
        <div class="text">
          <router-link class="secondary" :to="{ name: 'terms', hash: '#termsofservice' }">{{
            $t('terms_of_service')
          }}</router-link>
        </div>
        <div class="text">
          <router-link class="secondary" :to="{ name: 'terms-of-sale' }">{{ $t('terms_of_sale') }}</router-link>
        </div>
        <div class="text">
          <router-link class="secondary" :to="{ name: 'terms', hash: '#privacypolicy' }">{{
            $t('privacy_policy')
          }}</router-link>
        </div>
      </div>
      <div class="divider"></div>
      <div class="section copyrights_section">
        <div class="subtitle">
          <p>© {{ new Date().getFullYear() }} {{ $t('mobile_copyrights') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<style src="@/assets/css/footer.less" lang="less"></style>
<script>
export default {
  name: 'Footer',
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  }
}
</script>
