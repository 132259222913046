import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import cookieBanner from './modules/cookieBanner'
import auth from './modules/auth'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: true,
  plugins: [createPersistedState()],
  modules: {
    cookieBanner,
    auth
  }
})
