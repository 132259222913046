import { render, staticRenderFns } from "./WebApplication.vue?vue&type=template&id=2ee6d670&scoped=true&"
import script from "./WebApplication.vue?vue&type=script&lang=js&"
export * from "./WebApplication.vue?vue&type=script&lang=js&"
import style0 from "@/assets/css/services.less?vue&type=style&index=0&id=2ee6d670&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ee6d670",
  null
  
)

export default component.exports