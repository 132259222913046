<template>
  <div class="default">
    <Navbar></Navbar>
    <router-view />
    <Footer></Footer>
  </div>
</template>
<style src="@/assets/css/global.less" lang="less"></style>
<style src="@/assets/css/main-flex.css"></style>

<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
export default {
  components: {
    Navbar,
    Footer
  }
}
</script>
