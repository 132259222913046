<template>
  <div class="language-selector">
    <div
      class="flag"
      @click="isActive = !isActive"
      v-click-outside="
        () => {
          isActive = false
        }
      "
    >
      <img :src="'//cdn.squaretymedia.com/images/icons/' + lang + '.svg'" class="flag_button" alt="lang-flag" />
      <div v-bind:class="{ active: isActive }" v-if="isActive === true" class="flag_dropdown">
        <ul>
          <li class="dyn_flags">
            <a @click="changeLanguage('fr')"
              ><img src="//cdn.squaretymedia.com/images/icons/fr.svg" class="flag_button" alt="lang-flag"
            /></a>
            <a @click="changeLanguage('en')"
              ><img src="//cdn.squaretymedia.com/images/icons/en.svg" class="flag_button" alt="lang-flag"
            /></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<style scoped src="@/assets/css/components/language-selector.less" lang="less"></style>

<script>
import ClickOutside from 'vue-click-outside'

export default {
  name: 'LanguageSelector',
  data() {
    return {
      isActive: false,
      languages: ['en', 'fr']
    }
  },
  methods: {
    changeLanguage: function(lang) {
      let currentHash = this.$route.hash
      let to = this.$router.resolve({ params: { lang } })
      to.location.hash = currentHash
      this.$i18n.locale = lang
      this.$router.push(to.location)
    }
  },
  computed: {
    lang: function() {
      return this.$route.params.lang
    }
  },
  directives: {
    ClickOutside
  }
}
</script>
