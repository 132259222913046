import axios from 'axios'
import store from '../store'
import i18n from '../i18n'
import router from '../router'

export default () => {
  let api = axios.create({
    baseURL: process.env.VUE_APP_API_URL + '/' + i18n.locale + '/' + process.env.VUE_APP_API_VERSION,
    headers: {
      Authorization: `Bearer ${store.state.auth.token}`
    }
  })

  api.interceptors.response.use(
    function(response) {
      return response
    },
    function(error) {
      if (!error.response) {
        let globalMessage = {
          message: i18n.t('errors.api_not_responding'),
          icon: 'warning',
          bannerType: 'danger'
        }
        store.dispatch('informationBanner/setGlobalMessage', globalMessage)
        return Promise.reject(error)
      } else {
        if (error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
          // Get new token with refreshToken
          store.dispatch('auth/refreshAccessToken')

          // If the user did not authenticate again
          if (!store.getters['auht/isAuthenticated']) {
            store.dispatch('auth/logout')
            return router.push({ name: 'login' })
          }
          error.config.__isRetryRequest = true
          return api.request(error.config) // Retry the post/put/get/etc.
        } else {
          return Promise.reject(error)
        }
      }
    }
  )

  return api
}
