<template>
  <div class="navbar">
    <div class="pc col-pc-12 col-mobile-displaynone">
      <div class="left col-all-1">
        <div class="logo col-all-fit">
          <router-link to="home">
            <img src="//cdn.squaretymedia.com/images/logos/logo-dark.png" alt="" />
          </router-link>
        </div>
      </div>
      <div class="middle col-all-9">
        <div class="list">
          <ul class="uk-nav-default uk-nav-parent-icon" uk-nav>
            <router-link :to="{ name: 'home' }">
              <li>{{ $t('menu.home') }}</li>
            </router-link>
            <li class="dropdown">
              <a
                href="#"
                class="menu-label"
                v-bind:class="{ active: isPCProductsDropdownOpen }"
                @click="isPCProductsDropdownOpen = !isPCProductsDropdownOpen"
                >{{ $t('menu.products') }} <i class="fas fa-angle-left" v-show="isPCProductsDropdownOpen === false"></i
                ><i class="fas fa-angle-down" v-show="isPCProductsDropdownOpen === true"></i
              ></a>
              <ul class="uk-nav-sub" v-show="isPCProductsDropdownOpen === true">
                <router-link :to="{ name: 'web-application' }">
                  <li>{{ $t('menu.web_application') }}</li>
                </router-link>
                <router-link :to="{ name: 'website' }">
                  <li>{{ $t('menu.website') }}</li>
                </router-link>
              </ul>
            </li>
            <router-link :to="{ name: 'portfolio' }">
              <li>{{ $t('menu.portfolio') }}</li>
            </router-link>
            <router-link :to="{ name: 'contact-us' }">
              <li>{{ $t('menu.contact_us') }}</li>
            </router-link>
          </ul>
        </div>
      </div>
      <div class="right col-all-2">
        <ul class="language-selector">
          <LanguageSelector></LanguageSelector>
        </ul>
      </div>
    </div>
    <div class="mobile col-all-12 col-pc-displaynone">
      <div class="bar col-f col-all-12">
        <div class="left col-all-2">
          <div class="icon primary" @click="isMobileMenuOpen = !isMobileMenuOpen">
            <i class="fas fa-bars" v-show="isMobileMenuOpen === false"></i>
            <i class="fas fa-times" v-show="isMobileMenuOpen === true"></i>
          </div>
        </div>
        <div class="middle col-all-8">
          <div class="logo">
            <router-link :to="{ name: 'home' }">
              <img src="//cdn.squaretymedia.com/images/logos/logo-dark.png" alt="" />
            </router-link>
          </div>
        </div>
        <div class="right col-all-2">
          <!--<div class="icon primary">
            <i class="fas fa-user"></i>
          </div>-->
        </div>
      </div>
      <div class="menu col-all-12" v-show="isMobileMenuOpen === true">
        <div class="list col-f col-f-column">
          <ul>
            <router-link :to="{ name: 'home' }">
              <li>{{ $t('menu.home') }}</li>
            </router-link>
            <li class="submenu">
              <span>{{ $t('menu.products') }}</span>
              <ul>
                <router-link :to="{ name: 'web-application' }">
                  <li>{{ $t('menu.web_application') }}</li>
                </router-link>
                <router-link :to="{ name: 'website' }">
                  <li>{{ $t('menu.website') }}</li>
                </router-link>
                <!--
                <router-link :to="{ name: 'web-hosting' }">
                  <li>{{ $t('menu.web_hosting') }}</li>
                </router-link>
                -->
              </ul>
            </li>
            <router-link :to="{ name: 'portfolio' }">
              <li>{{ $t('menu.portfolio') }}</li>
            </router-link>
            <router-link :to="{ name: 'contact-us' }">
              <li>{{ $t('menu.contact_us') }}</li>
            </router-link>
            <!--<li>
              <div class="locale-changer">
                <select v-model="$i18n.locale">
                  <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">{{ lang }}</option>
                </select>
              </div>
            </li>-->
          </ul>
          <ul class="language-selector">
            <LanguageSelector></LanguageSelector>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<style src="@/assets/css/navbar.less" lang="less"></style>
<script>
import LanguageSelector from '@/components/LanguageSelector.vue'

export default {
  name: 'Navbar',
  components: {
    LanguageSelector
  },
  data() {
    return {
      isMobileMenuOpen: false,
      isPCProductsDropdownOpen: false
    }
  },
  watch: {
    $route() {
      this.isMobileMenuOpen = false
      this.isPCProductsDropdownOpen = false
    }
  }
}
</script>
